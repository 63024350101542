import React from 'react'

import PropTypes from 'prop-types'
import GaugeChart from 'react-gauge-chart'

// function calculatePercentage(total, max) {
//   return Math.round((total * 100) / max) / 100
// }

function Gauge(props) {
  const { final } = props

  return (
    <GaugeChart
      arcsLength={[0.5, 0.25, 0.25]}
      // TODO get those from CSS instead
      colors={['#df362d', '#efb54c', '#7bbd40']}
      textColor='#333333'
      needleColor='#333333'
      percent={final / 100}
      arcPadding={0.02}
    />
  )
}

Gauge.propTypes = {
  final: PropTypes.number.isRequired
}

export default Gauge
