import React from 'react'

import Circle from 'components/Circle'
import PropTypes from 'prop-types'
import { Card, Col, Row } from 'react-bootstrap'

function calculateLevel(score) {
  if (score.error) return Circle.LEVELS.DANGER
  if (!score.starttls && !score.count) return Circle.LEVELS.DANGER
  if (!score.starttls) return Circle.LEVELS.WARNING
  if (!score.count) return Circle.LEVELS.WARNING
  if (score.starttls && score.count) return Circle.LEVELS.GOOD

  return Circle.LEVELS.UNDEFINED
}

function getLevelDescription(level) {
  switch (level) {
    case Circle.LEVELS.DANGER:
      return (
        <div>
          <p>
            Your current mail server doesn’t support secure transmission of emails over
            TLS. This means your emails could potentially be read by a third party
            snooping your traffic. You can fix this quickly by ensuring your emails are
            sent through a mail server that uses TLS.
          </p>
          <p>
            <a
              target='_blank'
              rel='noreferrer'
              href='https://smxemail.com/smx-365-contact-us/'
            >
              Need some help? Get in touch with an email security specialist.
            </a>
          </p>
        </div>
      )
    case Circle.LEVELS.WARNING:
      return (
        <p>
          Your domain is only partially protected with TLS. Your TLS should be implemented
          via the STARTTLS command – check that your mail server offers this connection
          upgrade.
        </p>
      )
    case Circle.LEVELS.GOOD:
      return (
        <p>
          Congratulations, your emails are fully secured with TLS. It looks you’re well on
          your way to having industry-standard email security. If you haven’t already,
          check that your DMARC and SPF settings are all green too.
        </p>
      )
    default:
      return <p>{`No description available for level ${level}`}</p>
  }
}

const renderWarnings = (warnings) => {
  if (warnings?.length > 0) {
    const filteredWarnings = warnings.filter(
      (w) => !w.startsWith('SSL/TLS is not supported on')
    )
    if (filteredWarnings.length > 0) {
      return (
        <p className='mt-3'>
          <strong>Errors/Warnings:</strong> {filteredWarnings.join(', ')}
        </p>
      )
    }
    return undefined
  }
  return undefined
}

function Tls(props) {
  const { score, warnings } = props
  const level = calculateLevel(score)
  const description = getLevelDescription(level)
  return (
    <section>
      <p>
        Transport Layer Security (TLS) is a cryptographic protocol that helps ensure
        privacy and data integrity between email clients. Not all TLS is created equal.
        Attackers can easily see if your emails are being secured in transit using
        industry-standard techniques or if there are gaps they can exploit.
      </p>
      <Card body border='light' bg='light'>
        <Row>
          <Col xs={12} sm={4}>
            <Circle title='TLS' level={level} />
          </Col>
          <Col xs={12} sm={8}>
            {description}
          </Col>
        </Row>
      </Card>
      {renderWarnings(warnings)}
    </section>
  )
}

Tls.propTypes = {
  score: PropTypes.objectOf(PropTypes.number).isRequired,
  warnings: PropTypes.arrayOf(PropTypes.string).isRequired
}

Tls.calculateLevel = calculateLevel

export default Tls
