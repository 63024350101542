import 'fonts/AvenirNext.ttc'
import 'index.scss'

import React from 'react'
import ReactDOM from 'react-dom'
import GA4React from 'ga-4-react'

import Logo from 'components/Logo'

import Report from 'pages/Report'
import { Col, Container, Row } from 'react-bootstrap'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

const ga4react = new GA4React(process.env.REACT_APP_GA_MEASUREMENT_ID, {
  debug_mode: !process.env.production
})

;(async () => {
  try {
    await ga4react.initialize()
    // eslint-disable-next-line no-empty
  } catch {}
  ReactDOM.render(
    <React.StrictMode>
      <Container fluid='md'>
        <Row>
          <Col md={2}>
            <Row className='justify-content-between justify-content-md-start'>
              <Col xs={4} md={12}>
                <Logo />
              </Col>
              <Col xs={4} md={12} className='mt-md-4 mb-4 text-end text-md-start'>
                <a target='_blank' rel='noreferrer' href='https://smxemail.com/'>
                  SMX Home
                </a>
                <br />
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://smxemail.com/smx-for-enterprise/smx-365/'
                >
                  SMX 365
                </a>
                <br />
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://smxemail.com/our-company/'
                >
                  About SMX
                </a>
                <br />
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://smxemail.com/smx-365-contact-us/'
                >
                  Contact SMX
                </a>
                <div className='d-none d-md-block mt-4'>
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href='https://outlook.office365.com/owa/calendar/SMXLimitedEmailSecuritySpecialists@smxemail.com/bookings/'
                  >
                    Book a meeting with an email security specialist
                  </a>
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={1} className='side-wrapper d-md-block' />
          <Col md={{ span: 9, offset: 0 }}>
            <BrowserRouter>
              <Switch>
                <Route path='/report/:id'>
                  <Report GA4React={GA4React} />
                </Route>
                <Route path='/reportdomain/:domain'>
                  <Report GA4React={GA4React} />
                </Route>
                <Route path='*'>
                  <Report GA4React={GA4React} />
                </Route>
              </Switch>
            </BrowserRouter>
          </Col>
        </Row>
      </Container>
    </React.StrictMode>,
    document.getElementById('root')
  )
})()
