import React from 'react'
import { capitalCase } from 'change-case'

import Circle from 'components/Circle'
import Metrics from 'components/Metrics'
import PropTypes from 'prop-types'
import { Card, Col, Row } from 'react-bootstrap'

function calculateLevel(score) {
  if (score.dmarcValid === 0) return Circle.LEVELS.DANGER
  if (score.spfValid === 0) return Circle.LEVELS.DANGER
  if (score.dmarcPolicy === 1) return Circle.LEVELS.WARNING
  if (score.dmarcPolicy === 3) return Circle.LEVELS.GOOD

  return Circle.LEVELS.UNDEFINED
}

function getLevelDescription(level) {
  switch (level) {
    case Circle.LEVELS.DANGER:
      return (
        <div>
          <p>
            You don&apos;t have DMARC email security in place, so your domain is currently
            vulnerable to spoofing and forgery attacks – it&apos;s fairly easy for
            attackers to craft an email that looks like it&apos;s from you. This puts your
            staff, suppliers and customers at risk so addressing this should be a
            priority.
          </p>
          <p>
            The good news is that DMARC is free.{' '}
            <a
              target='_blank'
              rel='noreferrer'
              href='https://smxemail.com/our-company/blogs-news/blog/email-comes-of-age-with-dmarc/'
            >
              Click here for more information on DMARC, and why you need it.
            </a>
          </p>
        </div>
      )
    case Circle.LEVELS.WARNING:
      return (
        <div>
          <p>
            It looks like you&apos;ve begun your DMARC journey, so you have some
            protection against spoofing and forgery attacks. That&apos;s great, but
            there’s still work to do to get your domain fully protected. Check you have
            valid Sender Policy Framework and DMARC records, that you have a policy in
            place (other than p=none) and that all of your emails are subject to the
            policy.
          </p>
        </div>
      )
    case Circle.LEVELS.GOOD:
      return (
        <p>
          Well done, it looks like your DMARC policy is mature and properly configured.
          The next step is to make sure you&apos;re properly maintaining your DMARC. Keep
          an eye on your sending sources and adjust SPF as needed. Your provider&apos;s
          reporting console has these reports already so it’s just a matter of reading and
          analysing them.
        </p>
      )
    default:
      return <p>{`No description available for level ${level}`}</p>
  }
}

function Dmarc(props) {
  const { score, record, warnings, error, tags } = props
  const level = calculateLevel(score)
  const description = getLevelDescription(level)

  /* TODO too much vague, unsafe business logic here, should be on server side */
  const metrics = [
    {
      label: 'DMARC Valid',
      level: score.dmarcValid === 1 ? Circle.LEVELS.GOOD : Circle.LEVELS.DANGER,
      value: score.dmarcValid === 1 ? 'Yes' : 'No'
    },
    {
      label: 'SPF Valid',
      level: score.spfValid === 1 ? Circle.LEVELS.GOOD : Circle.LEVELS.DANGER,
      value: score.spfValid === 1 ? 'Yes' : 'No'
    }
  ]

  if (tags?.p) {
    metrics.push({
      label: 'DMARC Policy',
      level: tags.p.value === 'none' ? Circle.LEVELS.WARNING : Circle.LEVELS.GOOD,
      value: capitalCase(tags.p.value)
    })
  } else {
    metrics.push({
      label: 'DMARC Policy',
      level: Circle.LEVELS.WARNING,
      value: '?'
    })
  }
  if (tags?.rua) {
    metrics.push({
      label: 'DMARC Reporting',
      level: Circle.LEVELS.GOOD,
      value: 'Yes'
    })
  } else {
    metrics.push({
      label: 'DMARC Reporting',
      level: Circle.LEVELS.WARNING,
      value: '?'
    })
  }

  return (
    <section>
      <p>
        DMARC effectively stops spoofing and forgery by controlling who can send email
        from your domain. It&apos;s one of the most important security measures and can
        take just a few weeks to implement.
      </p>
      <Card body border='light' bg='light'>
        <Row>
          <Col xs={12} sm={4}>
            <Circle title='DMARC' level={level} />
          </Col>
          <Col xs={12} sm={8}>
            {description}
          </Col>
        </Row>
      </Card>
      {record && (
        <p className='mt-3'>
          <strong>Record:</strong>
          &nbsp;
          {record}
        </p>
      )}
      {error && (
        <p className='mt-3'>
          <strong>Error:</strong>
          &nbsp;
          {error}
        </p>
      )}
      {warnings?.length > 0 && (
        <p className='mt-3'>
          <strong>Warnings:</strong>
          &nbsp;
          {warnings.join(', ')}
        </p>
      )}
      <Metrics list={metrics} />
    </section>
  )
}

Dmarc.propTypes = {
  score: PropTypes.objectOf(PropTypes.number).isRequired,
  warnings: PropTypes.arrayOf(PropTypes.string),
  record: PropTypes.string,
  error: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  tags: PropTypes.object
}

Dmarc.defaultProps = {
  record: undefined,
  warnings: undefined,
  error: undefined,
  tags: undefined
}

Dmarc.calculateLevel = calculateLevel

export default Dmarc
