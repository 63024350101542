import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDeepCompareEffect } from 'react-use'

import axios from 'axios'
import Report from 'components/Report'
import Title from 'components/Title'
import Smx from 'components/sections/Smx'
import { Alert, Badge, Fade } from 'react-bootstrap'
import { useParams, useLocation } from 'react-router-dom'

const base64Encode = (str) => Buffer.from(str, 'utf8').toString('base64')
const base64Decode = (str) => Buffer.from(str, 'base64').toString('utf8')

function ReportPage({ GA4React }) {
  // const ga4 = useGA4React()
  let ga4 = false
  if (GA4React.isInitialized()) {
    ga4 = GA4React.getGA4React()
  }
  const location = useLocation()
  const { id, domain } = useParams()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [report, setReport] = useState(null)

  useDeepCompareEffect(() => {
    async function getReport() {
      let reportId = id
      try {
        setLoading(true)
        if (domain) {
          reportId = base64Encode(domain)
        }
        const response = await axios.get(`/api/HttpTrigger1?report-id=${reportId}`)
        setReport(response.data)
        if (ga4) {
          let gaCategory = 'found'
          if (Object.keys(response.data).length < 1) {
            gaCategory = 'notfound'
          }
          ga4.gtag('event', 'view_item', {
            items: [
              {
                item_name: base64Decode(reportId),
                item_category: gaCategory
              }
            ]
          })
        }
      } catch (exc) {
        if (ga4) {
          ga4.gtag('event', 'view_item', {
            items: [
              {
                item_name: base64Decode(reportId),
                item_category: 'exception'
              }
            ]
          })
        }
        setError(exc)
      } finally {
        setLoading(false)
      }
    }
    if (id || domain) {
      getReport()
    } else {
      if (ga4) {
        ga4.gtag('event', 'view_item', {
          items: [
            {
              item_name: 'empty',
              item_category: 'empty'
            }
          ]
        })
      }
      setLoading(false)
      setReport(null)
    }
  }, [ga4, id, domain])

  let content

  if (!(id || domain)) {
    content = <Smx />
  } else if (error) {
    content = <Alert variant='warning'>Oh snap! You got an error. Try again later!</Alert>
  } else if (!loading) {
    if (Object.keys(report).length < 1) {
      content = (
        <Alert variant='warning'>
          We have no information for your domain. Try again later!
        </Alert>
      )
    } else {
      content = <Report report={report} />
    }
  }

  return (
    <main>
      <div className='d-flex justify-content-between'>
        <div>
          <Title loading={loading} />
        </div>
        {!loading && report && report.domain && (
          <div>
            <Badge style={{ fontSize: '1.5em' }}>{report.domain}</Badge>
          </div>
        )}
      </div>

      {content && (
        <Fade in={!loading} appear>
          <div>{content}</div>
        </Fade>
      )}
      {location.search === '?debug=true' && report && (
        <pre>{JSON.stringify(report, undefined, 2)}</pre>
      )}
    </main>
  )
}

ReportPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  GA4React: PropTypes.object
}

ReportPage.defaultProps = {
  GA4React: undefined
}

export default ReportPage
