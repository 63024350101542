import React from 'react'

import Circle from 'components/Circle'
import Gauge from 'components/Gauge'
import Dmarc from 'components/sections/Dmarc'
import Provider from 'components/sections/Provider'
import Spf from 'components/sections/Spf'
import Tls from 'components/sections/Tls'
import PropTypes from 'prop-types'
import { Card, Col, Container, Row } from 'react-bootstrap'

function calculateLevel(final) {
  const overallGaugePercentage = final / 100

  if (overallGaugePercentage <= 0.5) {
    return Circle.LEVELS.DANGER
  }

  if (overallGaugePercentage <= 0.75) {
    return Circle.LEVELS.WARNING
  }

  if (overallGaugePercentage > 0.75) {
    return Circle.LEVELS.GOOD
  }

  return Circle.LEVELS.UNDEFINED
}

function getLevelDescription(level) {
  switch (level) {
    case Circle.LEVELS.DANGER:
      return (
        <div>
          <h4>Potentially at risk</h4>
          <p>
            Being in the red, lower-security bracket indicates your organisation’s emails
            could be at risk. There are a lot of things you can and should do to fix this
            problem, many of them free. Following security standards SPF and DMARC and
            implementing a secure email gateway will improve the email security of your
            domain immediately.
          </p>
          <p>
            <a
              target='_blank'
              rel='noreferrer'
              href='https://smxemail.com/smx-365-contact-us/'
            >
              Need some help? Get in touch with an email security specialist.
            </a>
          </p>
        </div>
      )
    case Circle.LEVELS.WARNING:
      return (
        <div>
          <h4>Average security</h4>
          <p>
            Being in the yellow, mid-level security bracket indicates your organisation
            has average email security. There are a lot of things you can and should do to
            improve, and many, such as DMARC and SPF, are free. Remember, this score is
            based on the information that SMX can see from public records. There may be
            further controls you have in place that we can’t see.
          </p>
        </div>
      )
    case Circle.LEVELS.GOOD:
      return (
        <div>
          <h4>Best practice security</h4>
          <p>
            Well done. Being in the green security bracket indicates your organisation is
            running best-practice email security. That’s an achievement, but as you know,
            good security also needs good maintenance. The best security maintenance
            practices include checking your DMARC reports for unusual activity and
            maintaining any dynamic SPF records.
          </p>
        </div>
      )
    default:
      return <p>{`No description available for level ${level}`}</p>
  }
}

function Intro(props) {
  const { report } = props
  const level = calculateLevel(report.scores.total.final)
  const description = getLevelDescription(level)

  return (
    <>
      <section className='intro'>
        <h4>How it started and how it’s calculated</h4>
        <p>
          To encourage more focus and discussion about email security, SMX began using
          publicly available information in the DNS to survey key indicators of reputable
          email senders. These include the update of DMARC, an important email security
          standard, SPF and TLS records and whether domains followed industry best
          practice for receiving email from the internet. The result? The free Domain Risk
          Assessment tool.
        </p>
      </section>
      <section className='intro'>
        <h4>Here’s the risk report for {report.domain}</h4>
        <p>
          SMX has analysed your domain based on records published in the DNS. The total
          score for your organisation is shown below. Scroll further down the page to see
          how we graded you on the individual components that make up your final score. If
          your score could do with improvement, we provide tips about which aspects you
          could focus on to get some quick wins.
        </p>
        <p>
          <a
            target='_blank'
            rel='noreferrer'
            href='https://outlook.office365.com/owa/calendar/SMXLimitedEmailSecuritySpecialists@smxemail.com/bookings/'
          >
            Need some help? Book a meeting with an email security specialist
          </a>
        </p>
        <Card body border='light' bg='light'>
          <Container fluid>
            <Row className='justify-content-around'>
              <Col xs={12} lg={6}>
                <Gauge final={report.scores.total.final} />
              </Col>
              <Col xs={12} lg={6}>
                {description}
              </Col>
            </Row>
          </Container>
        </Card>
      </section>
      <section className='intro' style={{ pageBreakBefore: 'always' }}>
        <h4 className='text-center mt-3'>Risk Breakdown</h4>
        <p>
          Your Risk score is calculated using inputs from these records sets – this means
          you have a robust breakdown of where you are doing well, or where there is room
          for improvement. Below we will provide you with in-depth details on each of
          these components – helping you to better understand and act on your Domain Risk
          Score.
        </p>
        <Card body border='light' bg='light'>
          <Container fluid>
            <Row className='justify-content-around circles'>
              <Col className='text-center' xs={6} lg={3}>
                <a href='#dmarcSection' title='View DMARC details'>
                  <Circle
                    title='DMARC'
                    level={Dmarc.calculateLevel(report.scores.dmarc)}
                  />
                </a>
              </Col>
              <Col className='text-center' xs={6} lg={3}>
                <a href='#spfSection' title='View SPF details'>
                  <Circle title='SPF' level={Spf.calculateLevel(report.scores.spf)} />
                </a>
              </Col>
              <Col className='text-center' xs={6} lg={3}>
                <a href='#providerSection' title='View Service Provider details'>
                  <Circle
                    title='Provider'
                    level={Provider.calculateLevel(report.scores.provider)}
                  />
                </a>
              </Col>
              <Col className='text-center' xs={6} lg={3}>
                <a href='#tlsSection' title='View TLS details'>
                  <Circle title='TLS' level={Tls.calculateLevel(report.scores.tls)} />
                </a>
              </Col>
            </Row>
          </Container>
        </Card>
      </section>
    </>
  )
}

Intro.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  report: PropTypes.object.isRequired
}

Intro.calculateLevel = calculateLevel

export default Intro
