import React from 'react'

import PropTypes from 'prop-types'
import { Spinner } from 'react-bootstrap'

function Title(props) {
  const { loading } = props

  return (
    <h1>
      SMX Domain Risk Assessment
      {loading && (
        <Spinner
          style={{ marginLeft: '1rem', verticalAlign: 'middle' }}
          animation='border'
          role='status'
          variant='info'
        />
      )}
    </h1>
  )
}

Title.propTypes = {
  loading: PropTypes.bool
}

Title.defaultProps = {
  loading: false
}

export default Title
