function Smx(props) {
  return (
    <>
      <p>
        SMX is a specialist provider – email security is all we do. That’s why we know
        what it takes to stay ahead of the game.
      </p>
      <h3>Trusted by government agencies for nearly two decades</h3>
      <p>
        <a target='_blank' rel='noreferrer' href='https://smxemail.com/our-company/'>
          Find out more
        </a>
      </p>
      <h3>Enhance your Microsoft enterprise security</h3>
      <p>SMX 365 adds the missing link to your email security.</p>
      <p>
        It doesn’t disable or compromise Microsoft 365’s security protections as other
        solutions do. Instead, it amplifies and adds to the protections, giving more
        complete protection, all managed from one dashboard.
      </p>
      <p>
        <a
          target='_blank'
          rel='noreferrer'
          href='https://smxemail.com/media/byzjibji/datasheet-smx-365_august2021.pdf'
        >
          Download the brochure to learn more
        </a>
      </p>
      <h3>Protect your email, protect your brand</h3>
      <p>
        <a
          target='_blank'
          rel='noreferrer'
          href='https://outlook.office365.com/owa/calendar/SMXLimitedEmailSecuritySpecialists@smxemail.com/bookings/'
        >
          Click here to book a meeting with an email security specialist
        </a>
      </p>
    </>
  )
}

export default Smx
