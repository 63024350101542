import React from 'react'

import PropTypes from 'prop-types'

function Metrics(props) {
  const { list } = props

  const metricItems = list.map((metric, i) => (
    <li key={metric.label} className={metric.level}>
      {metric.label} <em>{metric.value}</em>
    </li>
  ))

  return (
    <div className='metrics'>
      <p className='mb-2'>
        <strong>Metrics</strong>
      </p>
      <ul>{metricItems}</ul>
    </div>
  )
}

Metrics.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ).isRequired
}

export default Metrics
