import React from 'react'

import Circle from 'components/Circle'
import Metrics from 'components/Metrics'
import PropTypes from 'prop-types'
import { Card, Col, Row } from 'react-bootstrap'

function calculateLevel(score) {
  if (score.failType === 0) return Circle.LEVELS.DANGER
  if (score.failType === 1) return Circle.LEVELS.WARNING
  if (score.failType === 2) return Circle.LEVELS.GOOD

  return Circle.LEVELS.UNDEFINED
}

function getLevelDescription(level) {
  switch (level) {
    case Circle.LEVELS.DANGER:
      return (
        <div>
          <p>
            You don&apos;t have a valid SPF record which makes it easy for attackers to
            spoof your domain and send emails forging your address. Fixing this should be
            a priority. Creating an SPF record is also vital to deploying DMARC, the email
            security gold standard.
          </p>
          <p>
            <a
              target='_blank'
              rel='noreferrer'
              href='https://smxemail.com/smx-365-contact-us/'
            >
              Need some help? Get in touch with an email security specialist.
            </a>
          </p>
        </div>
      )
    case Circle.LEVELS.WARNING:
      return (
        <p>
          We can see you&apos;ve deployed SPF but you have configured your domain with a
          soft-fail. This is equivalent to allowing all senders and doesn&apos;t provide
          any protection. As such we mark your domain down for having a soft-fail.
          It&apos;s better to have SPF configured with a hard-fail policy to enforce your
          domain security.
        </p>
      )
    case Circle.LEVELS.GOOD:
      return (
        <p>
          Well done, your SPF is valid and appears to be working. If you haven&apos;t done
          so already, ensure your DMARC also passes validation.
        </p>
      )
    default:
      return <p>{`No description available for level ${level}`}</p>
  }
}

function Spf(props) {
  const { score, record, warnings, dnsLookups, error } = props
  const level = calculateLevel(score)
  const description = getLevelDescription(level)

  /* TODO too much vague, unsafe business logic here, should be on server side */
  const metrics = [
    {
      label: 'SPF Valid',
      level: score.spfValid === 1 ? Circle.LEVELS.GOOD : Circle.LEVELS.DANGER,
      value: score.spfValid === 1 ? 'Yes' : 'No'
    }
  ]

  if (score.spfValid === 1) {
    metrics.push({
      label: 'Includes #',
      level: Circle.LEVELS.GOOD,
      value: dnsLookups
    })
  }

  let spfPolicyLevel
  let spfPolicyValue
  if (score.spfValid === 1 && score.failType === 0) {
    spfPolicyLevel = Circle.LEVELS.GOOD
    spfPolicyValue = 'Permissive'
  } else if (score.failType === 1) {
    spfPolicyLevel = Circle.LEVELS.GOOD
    spfPolicyValue = 'Soft Fail'
  } else if (score.failType === 2) {
    spfPolicyLevel = Circle.LEVELS.GOOD
    spfPolicyValue = 'Hard Fail'
  } else {
    spfPolicyLevel = Circle.LEVELS.UNDEFINED
    spfPolicyValue = '?'
  }

  metrics.push({
    label: 'SPF Policy',
    level: spfPolicyLevel,
    value: spfPolicyValue
  })

  return (
    <section>
      <p>
        Sender Policy Framework (SPF) is another critical email security standard.
        Up-to-date SPF records further support DMARC by documenting your sending sources.
        This prevents forged sender addresses.
      </p>
      <p>
        SPF has been around since 2006 as an essential part of modern email security.
        It&apos;s crucial to a robust email security posture
      </p>
      <Card body border='light' bg='light'>
        <Row>
          <Col xs={12} sm={4}>
            <Circle title='SPF' level={level} />
          </Col>
          <Col xs={12} sm={8}>
            {description}
          </Col>
        </Row>
      </Card>
      {record && (
        <p className='mt-3'>
          <strong>Record:</strong>
          &nbsp;
          {record}
        </p>
      )}
      {error && (
        <p className='mt-3'>
          <strong>Error:</strong>
          &nbsp;
          {error}
        </p>
      )}
      {warnings?.length > 0 && (
        <p className='mt-3'>
          <strong>Warnings:</strong>
          &nbsp;
          {warnings.join(', ')}
        </p>
      )}
      <Metrics list={metrics} />
    </section>
  )
}

Spf.propTypes = {
  score: PropTypes.objectOf(PropTypes.number).isRequired,
  dnsLookups: PropTypes.number,
  warnings: PropTypes.arrayOf(PropTypes.string),
  record: PropTypes.string,
  error: PropTypes.string
}

Spf.defaultProps = {
  record: undefined,
  warnings: undefined,
  dnsLookups: null,
  error: undefined
}

Spf.calculateLevel = calculateLevel

export default Spf
