import React from 'react'

import Dmarc from 'components/sections/Dmarc'
import Intro from 'components/sections/Intro'
import Provider from 'components/sections/Provider'
import Spf from 'components/sections/Spf'
import Tls from 'components/sections/Tls'
import Smx from 'components/sections/Smx'
import PropTypes from 'prop-types'
import { Accordion } from 'react-bootstrap'

function Report(props) {
  const { report } = props

  return (
    <div>
      <h4>Helping organisations improve cyber-security awareness</h4>
      <Intro report={report} />
      <Accordion defaultActiveKey='0' flush>
        <Accordion.Item eventKey='0'>
          <Accordion.Header id='dmarcSection'>
            DMARC – prevents spoofing and forgery
          </Accordion.Header>
          <Accordion.Body>
            <Dmarc
              score={report.scores.dmarc}
              record={report.dmarc.record}
              warnings={report.dmarc.warnings}
              error={report.dmarc.error}
              tags={report.dmarc.tags}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion defaultActiveKey='0' flush style={{ pageBreakBefore: 'always' }}>
        <Accordion.Item eventKey='0'>
          <Accordion.Header id='spfSection'>
            SPF – documents sending sources
          </Accordion.Header>
          <Accordion.Body>
            <Spf
              score={report.scores.spf}
              record={report.spf.record}
              dnsLookups={report.spf.dns_lookups}
              warnings={report.spf.warnings}
              error={report.spf.error}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion defaultActiveKey='0' flush>
        <Accordion.Item eventKey='0'>
          <Accordion.Header id='providerSection'>
            Service Provider – industry best practice
          </Accordion.Header>
          <Accordion.Body>
            <Provider score={report.scores.provider} />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion defaultActiveKey='0' flush style={{ pageBreakBefore: 'always' }}>
        <Accordion.Item eventKey='0'>
          <Accordion.Header id='tlsSection'>
            TLS – privacy and data integrity
          </Accordion.Header>
          <Accordion.Body>
            <Tls score={report.scores.tls} warnings={report.mx.warnings} />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion defaultActiveKey='0' flush>
        <Accordion.Item eventKey='0'>
          <Accordion.Header>
            <strong>Introducing SMX</strong>
          </Accordion.Header>
          <Accordion.Body>
            <Smx />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  )
}

Report.propTypes = {
  report: PropTypes.oneOfType([PropTypes.object]).isRequired
}

export default Report
