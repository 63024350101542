import React from 'react'

import Circle from 'components/Circle'
import PropTypes from 'prop-types'
import { Card, Col, Row } from 'react-bootstrap'

function calculateLevel(score) {
  switch (score.trustedProvider) {
    case 0:
      return Circle.LEVELS.DANGER
    case 1:
      return Circle.LEVELS.WARNING
    case 2:
      return Circle.LEVELS.GOOD
    default:
      return Circle.LEVELS.UNDEFINED
  }
}

function getLevelDescription(level) {
  switch (level) {
    case Circle.LEVELS.DANGER:
      return (
        <>
          <p>
            You&apos;re not using any third-party SEG providers, so you&apos;re relying
            entirely on the skills and experience of your in-house IT teams, their vendors
            and support agreements. There are few scenarios where managing email security
            in-house can compare favourably with deploying third-party SEG providers in
            front of internet-facing domains. Your organisation should consider all the
            benefits of engaging an SEG provider to offload your email security burden.
          </p>
          <p>
            <a
              target='_blank'
              rel='noreferrer'
              href='https://smxemail.com/smx-365-contact-us/'
            >
              Need some help? Get in touch with an email security specialist.
            </a>
          </p>
        </>
      )
    case Circle.LEVELS.WARNING:
      return (
        <>
          <p>
            We can see you&apos;re using a 3rd party SEG provider, that&apos;s great. The
            only reason we&apos;ve marked your organisation down on this section is that
            your current provider is asking you to whitelist their delivery IPs which is
            disabling your Microsoft 365 controls.
          </p>
          <p>
            Many organisations are unaware of this state and so we&apos;re highlighting
            the risk of this configuration. To fix this issue your SEG needs to integrate
            with M365 without you whitelisting their delivery IPs.
          </p>
          <p>
            For more information see &nbsp;
            <a
              target='_blank'
              rel='noreferrer'
              href='https://smxemail.com/smx-for-enterprise/smx-365/'
            >
              https://smxemail.com/smx-for-enterprise/smx-365/
            </a>
          </p>
        </>
      )
    case Circle.LEVELS.GOOD:
      return (
        <p>
          It looks like all your emails are protected by a well-known SEG provider, so
          you&apos;ve taken care of a lot of the low-hanging fruit when it comes to
          implementing a good email security profile. This allows your IT team more time
          to focus on your core business while maintaining your current high standards of
          email security.
        </p>
      )
    default:
      return <p>{`No description available for level ${level}`}</p>
  }
}

function Provider(props) {
  const { score } = props
  const level = calculateLevel(score)
  const description = getLevelDescription(level)
  return (
    <section>
      <p>
        Implementing a specialist email gateway (SEG) can massively affect your email
        security profile. SEG providers, such as SMX, generally implement best practice
        for their entire customer base. They also provide dedicated support that&apos;s
        valued by most in-house IT departments.
      </p>
      <Card body border='light' bg='light'>
        <Row>
          <Col xs={12} sm={4}>
            <Circle title='Provider' level={level} />
          </Col>
          <Col xs={12} sm={8}>
            {description}
          </Col>
        </Row>
      </Card>
    </section>
  )
}

Provider.propTypes = {
  score: PropTypes.objectOf(PropTypes.number).isRequired
}

Provider.calculateLevel = calculateLevel

export default Provider
